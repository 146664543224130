import React from 'react'
import Helmet from 'react-helmet'
import pic01 from '../assets/images/pic01.jpg'
import drilling from '../assets/images/drilling.jpg'
import countersink from '../assets/images/countersink.jpg'
import riveting from '../assets/images/riveting.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'

import { useState } from 'react';
import rootDirectory from '../generated/pdfs.json';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Merlin Tools - Aerospace Production &amp; Maintenance Tools" />

        <Header />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p>
                  Merlin Tools provides the next generation of aerospace tooling for the production and maintenance of today's aircrafts. Providing all the necessary tooling, accessories and consultation for hole preparation, fastener installation and surface preparation.
                </p>
                <p>If you have any questions about our products or need assistance, please get in touch via email at <a href="mailto:info@merlintool.com?subject=Hello from merlintool.com">&#105;&#110;&#102;&#111;&#064;&#109;&#101;&#114;&#108;&#105;&#110;&#116;&#111;&#111;&#108;&#046;&#099;&#111;&#109;</a> or call us at <a href="tel:+1800-681-3780">1 (800) 681-3780</a>.</p>
                <p>
                  <a href="https://www.dropbox.com/sh/r2015iymzzzruec/AAB-iuoQ3cA1aBt3ugfSZatda?dl=1">Download our full catalog (22 MB)</a>
                </p>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Specialties</h2>
            </header>
            <ul className="features">
              <li>
                <span className="image image-li">
                  <img src={drilling} alt="" />
                </span>
                <h3>Drilling</h3>
                <p>Hand drills and accessories.</p>
              </li>
              <li>
                <span className="image image-li">
                  <img src={countersink} alt="" />
                </span>
                <h3>Countersinking</h3>
                <p>Countersink drills and accessories.</p>
              </li>
              <li>
                <span className="image image-li">
                  <img src={riveting} alt="" />
                </span>
                <h3>Riveting</h3>
                <p>Rivet hammers and accessories.</p>
              </li>
            </ul>
          </section>
          <section id="second" className="main section-pdfs">
            <header className="major">
              <h2>Catalog</h2>
            </header>
            <Directory
              name={rootDirectory.name}
              children={rootDirectory.children}
              isRoot={true}
            />
          </section>
        </div>
      </Layout>
    )
  }
}

function Directory({ name, children, isRoot = false, path = "" }) {
  const [isExpanded, setIsExpanded] = useState(isRoot ? true : false);
  const showDirectoryName = !isRoot;

  return (
    <ul
      style={{ cursor: 'pointer', userSelect: 'none' }}
      onClick={(e) => {
        e.stopPropagation();

        if (isRoot) {
          return;
        }

        setIsExpanded(!isExpanded)
      }}
    >
      {showDirectoryName && (
        <div className="directory">
          <span className="list-marker">
            {isExpanded ? '▼' : '▶'}
          </span>
          <span>{name}</span>
        </div>
      )}
      <li>
        {isExpanded && children.map(it => {
          if (it.isDirectory) {
            return (
              <Directory
                name={it.name}
                path={`${path}${it.name}/`}
                children={it.children}
              />
            );
          }

          return <File name={it.name} path={`${path}${it.name}`} />;
        })}
      </li>
    </ul>
  );
}

function File({ name, path }) {
  const displayName = name.replace('.pdf', '');

  return (
    <div class="list-item">
      <a
        href={`/pdfs/${path}`}
        target="_blank"
        rel="noreferrer"
      >
        {displayName}
      </a>
    </div>
  );
}

export default Index
